import React from "react";
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { GetSiteMetadata } from 'hooks/GetSiteMetadata';
import { getSrc } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import errorPageStyle from "assets/jss/views/errorPageStylesCustom.js";

// import header + footer
import HeaderTemplate from "components/Header/HeaderTemplate.js";
import HeaderScentsy from "components/Header/HeaderScentsy.js";
import FooterTemplate from "components/Footer/FooterTemplate.js";

import useIsClient from 'hooks/useIsClient';

const useStyles = makeStyles(errorPageStyle);

export default function ErrorPageTemplate(props) {

  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query errorPageBackgroundImage {
      bannerImage: imageSharp(resize: {originalName: {eq: "scentsy-wax-bars-on-tablecloth.jpg"}}) {
        gatsbyImageData(quality: 50)
      }
      bannerImageMobile: imageSharp(resize: {originalName: {eq: "scentsy-wax-bars-on-tablecloth.jpg"}}) {
        gatsbyImageData(width: 1200)
      }
    }
  `)

  const { isClient } = useIsClient();
  if (isClient) {
    var isMobile = window.matchMedia('(max-width: 600px)').matches;
    var bannerImage;
    if (isMobile) {
      bannerImage = getSrc(data.bannerImageMobile)
    } else {
      bannerImage = getSrc(data.bannerImage)
    }
  }

  // for SEO path
  const { siteMetadata } = GetSiteMetadata();
  var path = props.location.pathname;
  if (path.includes("/usa")) {
    path = path.replace("/usa", "");
  }

  return (
    <div>

      <GatsbySeo
        noindex={true}
        nofollow={true}
        title={'Page Not Found' + props.pageContext.locale.appendToTitle}
        description={'Page not found. What can we help you with?'}
        canonical={siteMetadata.siteUrl + props.pageContext.locale.path + path}
        language={props.pageContext.locale.isoLanguageCode}
        languageAlternates={[
          {
            hrefLang: props.pageContext.locale.isoLanguageCode,
            href: siteMetadata.siteUrl + props.pageContext.locale.path + path,
          },
          {
            hrefLang: props.pageContext.altLocale.isoLanguageCode,
            href: siteMetadata.siteUrl + props.pageContext.altLocale.path + path,
        }
        ]}
        openGraph={{
          type: 'website',
          title: 'Page Not Found' + props.pageContext.locale.appendToTitle,
          description: 'Page not found. What can we help you with?',
          url: siteMetadata.siteUrl + props.pageContext.locale.path + path,
          site_name: 'SCENTSY Online Store | Home Fragrance Biz',
          locale: props.pageContext.locale.ogLanguageCode,
          images: [
            {
              url: siteMetadata.siteUrl + '/files/images/Home_Fragrance_Biz-Independent_Scentsy_Consultant-Logo-rectangle.png',
              alt: 'Home Fragrance Biz Logo',
              width: 750,
              height: 450
            }]
        }}
      />
      <HeaderTemplate
        locale={props.pageContext.locale}
        location={props.location}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: `url(${bannerImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
       <div className={classes.container}>
         <HeaderScentsy  />
       </div>
        {/* <div className={classes.container}> */}
        <div className={classes.contentCenter}>
          <GridContainer>
            <GridItem md={12}>
              <h1 className={classes.title}>404</h1>
              <h2 className={classes.subTitle}>Page not found :(</h2>
              <h4 className={classes.description}>
                Ooooups! Looks like you got lost.
              </h4>
            </GridItem>
          </GridContainer>
        </div>
        {/* </div> */}
      </div>
      <FooterTemplate
        locale={props.pageContext.locale}
      />
    </div>
  );
}